import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { ApiSettings, CalendarEventSettings, CalendarSettings } from '../../settings.class';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';


import * as moment from 'moment';
import { EntitiesService } from 'app/entities/services/entities.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'con-missing-events',
    templateUrl: './missing-events.component.html'
})

export class MissingEventsComponent implements OnChanges, OnDestroy {

    private componentDestroyed$: Subject<boolean> = new Subject();

    @Input() company: any;
    @Input() ownerType: string;
    @Output() refreshList: EventEmitter<any> = new EventEmitter();

    public loading: boolean;
    public error: boolean;
    public missingTypes: any;
    public now: string = this.dateConverter.toEntityString(moment());
    public tomorrow: string = this.dateConverter.toEntityString(moment().add(1, 'days').startOf('day'));
    public showNewEventFlag = false;
    public expansionDict: any = {};
    public expanded = true;
    public commentLangDefault = CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG;
    public newEventDirectProfileLangs: any = {};
    constructor(private http: HttpClient,
                public profile: ProfileService,
                private dateConverter: DateConverterService,
                private enityService: EntitiesService
            ) {}

    showAll() {
        this.missingTypes.forEach(event => {
            event.expanded = true;
        });
        this.company.expanded = true;
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }
    public toggleType(event?) {
        if (event) {
            event.expanded = !event.expanded;
        } else {
           this.expanded = ! this.expanded;
        }
    }
    showEvent(event?) {
        if (!event && (this.company.expanded)) {
            return true;
        } else if (event && event.expanded) {
            return true;
        } else {
            return false;
        }
    }
    clearEditor() {
        if (this.company.currentEditingEvent) {
            this.company.currentEditingEvent = null;
        } else {
            this.showNewEventFlag = false;
        }
    }
    setCommentFlag(flag, event?) {
        if (!event) {
            this.company.expanded = flag;
        } else {
            event.expanded = flag;
        }
    }
    getOwnerTypeUri(): string {
        const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
        return url;
    }

    getMutations(event = null, newEvent?) {
        if (event) {
            const mutation = event.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
            return mutation;
        } else if (newEvent) {
            const mutation = this.company.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
            return mutation;
        } else {
            const mutation = this.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
            return mutation;
        }
    }

    show() {
        if (!this.loading && !this.error && this.expanded) {
            return true;
        } else {
            return false;
        }
    }
    newEventChanged() {
        this.refreshList.emit();
    }
    public eventCreated(event: any) {
        event.editingCommentsDict = {};
        const id = event.calendar_event_type.id;
        const indx = this.missingTypes.findIndex(t => t.id === id);
        this.missingTypes[indx].missing_event = event;
        let allEventsCreated = true;
        this.missingTypes.forEach(missing => {
            if (!missing.missing_event || !missing.missing_event.id) {
                allEventsCreated = false;
            }
        });
        if (allEventsCreated) {
            this.http.put(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.MISSING_FIXED_ENDPOINT, {})
                     .subscribe();
        }
        this.refreshList.emit();
        this.direktProfileChanged(event?.direkt_profile, event?.id);
        this.refreshList.emit();
    }
    toggleEditOfComment(comment, currentEditingEvent) {
        if (!(comment.id in currentEditingEvent.editingCommentsDict)) {
            currentEditingEvent.editingCommentsDict[comment.id] = false;
        }
        currentEditingEvent.editingCommentsDict[comment.id] = !currentEditingEvent.editingCommentsDict[comment.id];
    }
    isEditingComment(comment, currentEditingEvent?) {
        if (currentEditingEvent) {
            return currentEditingEvent.editingCommentsDict[comment.id];
        } else {
            return false;
        }
    }
    commentAdded(comment, currentEditingEvent, reloadComments = false) {
        if (!reloadComments) {
            currentEditingEvent.comments.push(comment);
        } else {
            currentEditingEvent.isLoadingComments = true;
            this.enityService.getEntityById('CalendarEvent', currentEditingEvent?.id).pipe(takeUntil(this.componentDestroyed$)).subscribe( (response: any) => {
                currentEditingEvent.comments = response.comments;
                currentEditingEvent.isLoadingComments = false;
            }, () => {
                currentEditingEvent.comments.push(comment);
                currentEditingEvent.isLoadingComments = false;
            });
        }
    }
    commentUpdated(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
          currentEditingEvent.comments[index] = comment;
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }
    commentDeleted(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
            currentEditingEvent.comments.splice(index, 1);
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }
    ngOnChanges() {
        if (this.company && this.company.id) {
            this.loading = true;
            this.http.get(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.MISSING_ENDPOINT)
                    .subscribe(res => {
                         this.missingTypes = res;
                         this.showAll();
                         this.loading = false;
                     }, err => {
                         console.log(err);
                         this.error = true;
                         this.loading = false;
                     });
        }
    } 

    direktProfileChanged(event: any, eventId:number) {
        if(!event)
            this.newEventDirectProfileLangs[eventId] = { languageName: null};
        else {
            this.newEventDirectProfileLangs[eventId] = { languageName: event?.language?.name || 'Swedish'};
        }
    }

    afterNewEventDeleted() {
        this.company.currentEditingEvent = null;
        this.refreshList.emit();
    }

    afterEventDeleted(event: any) {
        event.missing_event = null;
        this.refreshList.emit();
    }
}
