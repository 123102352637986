import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EstimatesService } from '../../services/estimates.service';
import { EstimatesSettings } from 'app/settings.class';

@Component({
  selector: 'con-consenus-snapshot',
  templateUrl: './consenus-snapshot.component.html',
  styleUrls: ['./consenus-snapshot.component.scss']
})
export class ConsenusSnapshotComponent implements OnInit {
  @Input() modules: any[];

  modulesFormFieldArray: FormArray = this.fb.array([]);
  consensusSnapshotForm: FormGroup;
  collapsedNodes = [];
  @Input() isSurveyLocked: boolean;

  constructor(private fb: FormBuilder, private estimateService: EstimatesService) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.modulesFormFieldArray.clear();
    this.obtainFlattenedModules(this.modules, 0).forEach(module => {
      this.modulesFormFieldArray.push(this.createModuleFormGroup(module));
    });

    // Combine all forms into one parent form
    this.consensusSnapshotForm = this.fb.group({
      modules: this.modulesFormFieldArray
    });
    if(this.isSurveyLocked) {
      this.consensusSnapshotForm.disable();
    }
  }
  // Function for the case when validators are fired when data is entered
  createModuleFormGroup(module) {
    return this.fb.group({
      line_id: module.line_id,  //Assuming   unique line_id
      name: module.name,
      mean: new FormControl(this.estimateService.setFormValue(module.mean), [Validators.pattern(EstimatesSettings.DECIMAL_WITH_NEGATIVE_REGEX)]),
      median: new FormControl(this.estimateService.setFormValue(module.median), [Validators.pattern(EstimatesSettings.DECIMAL_WITH_NEGATIVE_REGEX)]),
      high: new FormControl(this.estimateService.setFormValue(module.high), [Validators.pattern(EstimatesSettings.DECIMAL_WITH_NEGATIVE_REGEX)]),
      low: new FormControl(this.estimateService.setFormValue(module.low), [Validators.pattern(EstimatesSettings.DECIMAL_WITH_NEGATIVE_REGEX)]),
      contributors_count: new FormControl(this.estimateService.setFormValue(module.contributors_count), [Validators.pattern(EstimatesSettings.INTEGER_REGEX)]),
      level: new FormControl(module.level || 0),
      parentId: new FormControl(module.parentId || null),
      isExpanded: new FormControl(true),
      hasChildren: new FormControl(module.hasChildren),
      alias: new FormControl(module.alias || null)
    });
  }



  obtainFlattenedModules(modules, level = 0, parentId = null) {
    let flatModules = [];
    for (let module of modules) {
      module.level = level;
      module.parentId = parentId;
      flatModules.push(module);
      if (module.children) {
        module.hasChildren = true;
        flatModules.push(...this.obtainFlattenedModules(module.children, level + 1, module.line_id));
      }
    }
    return flatModules;
  }

  toggleExpand(moduleFormGroup: FormGroup) {
    const currentExpandState = moduleFormGroup.controls['isExpanded'].value;
    moduleFormGroup.controls['isExpanded'].setValue(!currentExpandState);

    if (!currentExpandState) {
      return;
    }

    const rowsToBeCollapsed = [];
    // When the node is collapsed, we add its line_id to the array
    this.collapsedNodes.push(moduleFormGroup.controls['line_id'].value);

    (this.consensusSnapshotForm.get('modules') as FormArray).controls.forEach(control => {
      let parentId = control.get('parentId').value;

      while (parentId) {
        if (parentId === moduleFormGroup.controls['line_id'].value && this.collapsedNodes.includes(control.get('line_id').value)) {
          control.get('isExpanded').setValue(false);
          rowsToBeCollapsed.push(control);
          break;
        }
        parentId = (this.consensusSnapshotForm.get('modules') as FormArray).controls.find(c => c.get('line_id').value === parentId)?.get('parentId').value;
      }
    });
  }
  shouldShowRow(rowFormGroup: FormGroup) {
    let parentId = rowFormGroup.controls['parentId'].value;

    while (parentId != null) {
      const parentControl = this.modulesFormFieldArray.controls.find(control => control.get('line_id').value === parentId);

      if (parentControl && parentControl.get('isExpanded').value === false) {
        return false;
      }

      parentId = parentControl ? parentControl.get('parentId').value : null;
    }

    return true;
  }
  hasChildren(hasChildren: any) {
    return !!hasChildren;
  }
  onSubmit() {
    this.collapsedNodes = [];
  }
  levelClass(moduleFormGroup) {
    return `level-${moduleFormGroup.value.level}`;
  }
}
