import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { EntitiesService } from '../../entities/services/entities.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import { ReplaySubject,  SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { switchMap, tap, combineLatest, takeUntil } from 'rxjs/operators';


import * as moment from 'moment-timezone';
import { CalendarEventSettings } from 'app/settings.class';


@Component({
  selector: 'con-month-shower',
  templateUrl: './month-shower.component.html',
  styleUrls: ['./month-shower.component.scss']
})
export class MonthShowerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() date: any;
  @Input() showEditor = true;
  @Input() searchParams: any;
  
  private componentDestroyed$: Subject<boolean> = new Subject();
  private intervalSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private intervalSubcription: ISubscription;

  public availableTimezones: any = moment.tz.names();
  public timezoneControl: UntypedFormControl = new UntypedFormControl();
  public now: any;

  private startDay: any;
  private endDay: any;
  public weeks: any;

  public loading: boolean;

  public currentEditingEvent: any = null;
  private editingCommentsDict: any = {};
  public newEventDirectProfileLang: any = {};
  public commentLangDefault = CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG;
  public isLoadingComments = false;

  constructor(private entitiesService: EntitiesService,
              private dateConverter: DateConverterService,
              private enityService: EntitiesService
          ) {}

  ngOnInit() {
    this.now = this.dateConverter.toEntityString(moment())
    this.intervalSubcription = this.intervalSubject
      .pipe(tap(() => (this.loading = true)))
      .pipe(switchMap(dates => this.loadEvents(dates)))
      .pipe(combineLatest(this.timezoneControl.valueChanges))
      .subscribe(
        result => {
          this.generateWeeks(result[0].data, result[1]);
          this.loading = false;
        },
        err => console.log(err)
      );

    this.timezoneControl.patchValue(moment.tz.guess());
  }

  ngOnChanges() {
    // Set date to start of the month
    this.calculateStartDay();

    this.calculateEndDay();

    this.refresh();
  }

  refresh() {
    this.intervalSubject.next({
      startDay: this.startDay,
      endDay: this.endDay
    });
  }

  ngOnDestroy() {
    this.intervalSubcription.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  putInEditor(calendarEvent: any, el: HTMLElement) {
    this.clearEditor();
    setTimeout(()=> {
      this.currentEditingEvent = calendarEvent;
      el.scrollIntoView({behavior: 'smooth'});
      this.editingCommentsDict = {};
    }, 1);
    this.direktProfileChanged(calendarEvent?.direkt_profile, calendarEvent.id);
  }

  loadEvents(dates: any) {
    this.loading = true;
    const from = dates.startDay.clone();
    from.subtract(1, 'days');
    const to = dates.endDay.clone();
    to.add(1, 'days');
    return this.entitiesService.searchEntities(
      'CalendarEvent',
      {
        ...this.searchParams,
        from_date: [
          'f:geq:' + from.format('YYYY-MM-DD'),
          'f:leq:' + to.format('YYYY-MM-DD')
        ]
      },
      {
        page: 1,
        order_by: 'from_date',
        order_asc: true,
        per_page: 10000
      }
    );
  }

  calculateStartDay() {
    this.startDay = this.date.clone();
    this.startDay.date(1);
    while (this.startDay.isoWeekday() > 5) {
      this.startDay.add(1, 'days');
    }
  }

  calculateEndDay() {
    this.endDay = this.startDay.clone();
    this.endDay.endOf('month');
  }

  generateWeeks(events: any, timezone: string) {
    this.weeks = [];
    this.startDay.tz(timezone);
    this.endDay.tz(timezone);

    let date = this.startDay.clone();
    date.subtract(date.isoWeekday(), 'days');
    let previousDate = date;
    let week = [];
    while (
      date.isBefore(this.endDay, 'date') ||
      date.isSame(previousDate, 'week')
    ) {
      // Check if weekday
      if (date.isoWeekday() < 6) {
        if (!date.isSame(previousDate, 'week')) {
          this.weeks.push(week);
          week = [];
        }
        week.push({
          date,
          events: []
        });
        previousDate = date;
      }
      date = date.clone();
      date.add(1, 'day');
    }
    this.weeks.push(week);

    events.forEach(event => {
      event.from_date = moment(event.from_date).tz(timezone);
      if (event.to_date !== null) {
        event.to_date = moment(event.to_date).tz(timezone);
      }
    });

    let currentWeek = 0;
    let currentDay = 0;
    events.forEach(event => {
      while (
        currentWeek < this.weeks.length &&
        event.from_date.isAfter(
          this.weeks[currentWeek][currentDay].date,
          'date'
        )
      ) {
        currentDay++;
        if (currentDay > this.weeks[currentWeek].length - 1) {
          currentDay = 0;
          currentWeek++;
        }
      }
      if (currentWeek < this.weeks.length) {
        this.weeks[currentWeek][currentDay].events.push(event);
      }
    });
  }

  isHighlightedMonth(date: any) {
    return date.isSame(this.date, 'month');
  }

  isToday(date: any) {
    return date.isSame(moment(), 'date');
  }

  eventChanged(event: any) {
    this.currentEditingEvent = event;
    this.refresh();
  }

  clearEditor() {
    this.currentEditingEvent = null;
  }

  toggleEditOfComment(comment) {
    if (!(comment.id in this.editingCommentsDict)) {
      this.editingCommentsDict[comment.id] = false;
    }

    this.editingCommentsDict[comment.id] = !this.editingCommentsDict[comment.id];
  }

  isEditingComment(comment) {
    return this.editingCommentsDict[comment.id];
  }

  commentAdded(comment) {
    this.isLoadingComments = true;
    this.enityService.getEntityById('CalendarEvent', this.currentEditingEvent?.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      this.currentEditingEvent.comments = response.comments;
      this.isLoadingComments = false;
    }, () => {
      this.currentEditingEvent.comments.push(comment);
      this.isLoadingComments = false;
    });
    this.currentEditingEvent.comments.push(comment);
  }

  commentUpdated(comment) {
    const index = this.currentEditingEvent.comments.findIndex(c => c.id === comment.id);

    if (index > -1) {
      this.currentEditingEvent.comments[index] = comment;
    }

    this.toggleEditOfComment(comment);
  }

  commentDeleted(comment) {
    const index = this.currentEditingEvent.comments.findIndex(c => c.id === comment.id);

    if (index > -1) {
      this.currentEditingEvent.comments.splice(1, index);
    }

    this.toggleEditOfComment(comment);
  }

  eventDeleted(event: any) {
    this.clearEditor();

    this.refresh();
  }

  showEventWithoutLink(event) {
    if (event.owner_type !== 'Company' && event.owner_type !== 'Institution') {
      return true;
    } else {
      return false;
    }
  }

  showCompanyEventWithLink(event) {
    if (event.owner_type === 'Company') {
      return true;
    } else {
      return false;
    }
  }

  showInstitutionEventWithLink(event) {
    if (event.owner_type === 'Institution') {
      return true;
    } else {
      return false;
    }
  }

  direktProfileChanged(event: any, eventId:number) {
    if(!event)
        this.newEventDirectProfileLang[eventId] = { languageName: null};
    else {
        this.newEventDirectProfileLang[eventId] = { languageName: event?.language?.name || 'Swedish'};
    }
}
}
